<template>
  <div
    class="instruction-modal"
    :class="{ 'instruction-modal-short': !isDetailedInstruction }"
    data-t="web-push-instruction-modal"
  >
    <h2 class="instruction-title">{{ title }}</h2>
    <section v-if="isDetailedInstruction" class="instruction-content">
      <div
        v-for="(item, index) in detailedInstruction"
        :key="index"
        class="instruction-item"
      >
        <p>{{ item.text }}</p>
        <img :src="item.image" />
      </div>
    </section>
    <section v-else class="instruction-content-other">
      <div
        v-for="(item, index) in otherInstruction"
        :key="index"
        class="instruction-item-other"
      >
        <p>{{ item.text }}</p>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import type {
  UserAgentBrowsers,
  UserAgentOS,
} from '@st/utils/lib/parseUserAgent/types'
import SafariEn1 from './assets/notifications_macos_step_1_en.jpg'
import SafariEs1 from './assets/notifications_macos_step_1_es.jpg'
import SafariPt1 from './assets/notifications_macos_step_1_pt.jpg'
import SafariRu1 from './assets/notifications_macos_step_1_ru.jpg'
import SafariEn2 from './assets/notifications_macos_step_2_en.jpg'
import SafariEs2 from './assets/notifications_macos_step_2_es.jpg'
import SafariPt2 from './assets/notifications_macos_step_2_pt.jpg'
import SafariRu2 from './assets/notifications_macos_step_2_ru.jpg'
import SafariEn3 from './assets/notifications_macos_step_3_en.jpg'
import SafariEs3 from './assets/notifications_macos_step_3_es.jpg'
import SafariPt3 from './assets/notifications_macos_step_3_pt.jpg'
import SafariRu3 from './assets/notifications_macos_step_3_ru.jpg'
import SafariEn4 from './assets/notifications_macos_step_4_en.jpg'
import SafariEs4 from './assets/notifications_macos_step_4_es.jpg'
import SafariPt4 from './assets/notifications_macos_step_4_pt.jpg'
import SafariRu4 from './assets/notifications_macos_step_4_ru.jpg'
import ChromeEn1 from './assets/notifications_chrome_step_1_en.jpg'
import ChromeEs1 from './assets/notifications_chrome_step_1_es.jpg'
import ChromePt1 from './assets/notifications_chrome_step_1_pt.jpg'
import ChromeRu1 from './assets/notifications_chrome_step_1_ru.jpg'
import ChromeEn2 from './assets/notifications_chrome_step_2_en.jpg'
import ChromeEs2 from './assets/notifications_chrome_step_2_es.jpg'
import ChromePt2 from './assets/notifications_chrome_step_2_pt.jpg'
import ChromeRu2 from './assets/notifications_chrome_step_2_ru.jpg'
import ChromeEn3 from './assets/notifications_chrome_step_3_en.jpg'
import ChromeEs3 from './assets/notifications_chrome_step_3_es.jpg'
import ChromePt3 from './assets/notifications_chrome_step_3_pt.jpg'
import ChromeRu3 from './assets/notifications_chrome_step_3_ru.jpg'
import ChromeEn4 from './assets/notifications_chrome_step_4_en.jpg'
import ChromeEs4 from './assets/notifications_chrome_step_4_es.jpg'
import ChromePt4 from './assets/notifications_chrome_step_4_pt.jpg'
import ChromeRu4 from './assets/notifications_chrome_step_4_ru.jpg'
import ChromeEn5 from './assets/notifications_chrome_step_5_en.jpg'
import ChromeEs5 from './assets/notifications_chrome_step_5_es.jpg'
import ChromePt5 from './assets/notifications_chrome_step_5_pt.jpg'
import ChromeRu5 from './assets/notifications_chrome_step_5_ru.jpg'

const { browser } = defineProps<{
  os: UserAgentOS
  browser: UserAgentBrowsers
}>()
const { t, locale } = useI18n()

const macosImages: Record<string, string>[] = [
  {
    en: SafariEn1,
    es: SafariEs1,
    pt: SafariPt1,
    ru: SafariRu1,
  },
  {
    en: SafariEn2,
    es: SafariEs2,
    pt: SafariPt2,
    ru: SafariRu2,
  },
  {
    en: SafariEn3,
    es: SafariEs3,
    pt: SafariPt3,
    ru: SafariRu3,
  },
  {
    en: SafariEn4,
    es: SafariEs4,
    pt: SafariPt4,
    ru: SafariRu4,
  },
]

const chromeImages: Record<string, string>[] = [
  {
    en: ChromeEn1,
    es: ChromeEs1,
    pt: ChromePt1,
    ru: ChromeRu1,
  },
  {
    en: ChromeEn2,
    es: ChromeEs2,
    pt: ChromePt2,
    ru: ChromeRu2,
  },
  {
    en: ChromeEn3,
    es: ChromeEs3,
    pt: ChromePt3,
    ru: ChromeRu3,
  },
  {
    en: ChromeEn4,
    es: ChromeEs4,
    pt: ChromePt4,
    ru: ChromeRu4,
  },
  {
    en: ChromeEn5,
    es: ChromeEs5,
    pt: ChromePt5,
    ru: ChromeRu5,
  },
]

const title = computed(() => {
  if (browser === 'safari') {
    return t('webPush.instruction.desktop.titleMacos')
  }
  if (browser === 'google-chrome') {
    return t('webPush.instruction.desktop.titleChrome')
  }
  return t('webPush.instruction.desktop.titleOther')
})

const macosInstruction = [
  {
    text: t('webPush.instruction.desktop.textMacos1'),
    image: macosImages[0][locale.value],
  },
  {
    text: t('webPush.instruction.desktop.textMacos2'),
    image: macosImages[1][locale.value],
  },
  {
    text: t('webPush.instruction.desktop.textMacos3'),
    image: macosImages[2][locale.value],
  },
  {
    text: t('webPush.instruction.desktop.textMacos4'),
    image: macosImages[3][locale.value],
  },
]
const chromeInstruction = [
  {
    text: t('webPush.instruction.desktop.textChrome1'),
    image: chromeImages[0][locale.value],
  },
  {
    text: t('webPush.instruction.desktop.textChrome2'),
    image: chromeImages[1][locale.value],
  },
  {
    text: t('webPush.instruction.desktop.textChrome3'),
    image: chromeImages[2][locale.value],
  },
  {
    text: t('webPush.instruction.desktop.textChrome4'),
    image: chromeImages[3][locale.value],
  },
  {
    text: t('webPush.instruction.desktop.textChrome5'),
    image: chromeImages[4][locale.value],
  },
]

const otherInstruction = [
  {
    text: t('webPush.instruction.desktop.textOther1'),
  },
  {
    text: t('webPush.instruction.desktop.textOther2'),
  },
  {
    text: t('webPush.instruction.desktop.textOther3'),
  },
]

const detailedInstruction = computed(() =>
  browser === 'google-chrome' ? chromeInstruction : macosInstruction,
)
const isDetailedInstruction = computed(
  () => browser === 'google-chrome' || browser === 'safari',
)
</script>

<style scoped>
.instruction-modal {
  counter-reset: instruction;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-300);

  width: 596px;
  min-height: min(85vh, 760px);
  max-height: min(85vh, 760px);
  padding: var(--spacing-300) 0;
}

.instruction-modal-short {
  min-height: auto;
}

.instruction-title {
  margin: 0;
  padding: 0 var(--spacing-300);
  font: var(--desktop-text-2xl-semibold);
}

.instruction-content,
.instruction-content-other {
  scrollbar-color: var(--background-tertiary);
  scrollbar-width: thin;

  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-300);

  height: 100%;
  padding: 0 var(--spacing-300);
}

.instruction-content-other {
  gap: var(--spacing-150);
  font: var(--desktop-text-md-medium);
  color: var(--text-secondary);
}

.instruction-item {
  p {
    margin: 0;
    margin-bottom: var(--spacing-125);
    font: var(--desktop-text-md-medium);
    color: var(--text-secondary);
  }

  img {
    display: block;

    width: 100%;
    margin-top: var(--spacing-100);

    object-fit: cover;
    border-radius: var(--border-radius-150);
  }
}

.instruction-item-other {
  p {
    position: relative;
    margin: 0;
    margin-left: 30px; /* stylelint-disable-line */

    &::before {
      content: '';

      position: absolute;
      top: 50%;
      left: -30px;
      transform: translateY(-50%);

      width: 20px;
      height: 20px;

      background-color: var(--background-tertiary);
      border-radius: var(--border-radius-full);
    }

    &::after {
      content: counter(instruction);
      counter-increment: instruction;

      position: absolute;
      top: 50%;
      left: -24px;
      transform: translateY(-50%);

      font: var(--desktop-text-sm-semibold);
      color: var(--text-primary);
      text-align: center;
    }
  }
}
</style>
